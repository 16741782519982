import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import ServiceHero from "../../components/service-hero";
import PhotoSide from "../../components/photo-side";
import Testimonials from "../../components/testimonials";
import Certifications from "../../components/certifications";
import DotsCta from "../../components/dots-cta";

const ElectricVehicle = () => {
  const data = useStaticQuery(
    graphql`
      query {
        heroImg: file(relativePath: { eq: "notepad-hero.png" }) {
          publicURL
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: FULL_WIDTH
              placeholder: BLURRED
            )
          }
        }
        logoImg: file(relativePath: { eq: "logo.png" }) {
          publicURL
        }
        notepadImg: file(relativePath: { eq: "notepad.png" }) {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
        ownerImg: file(relativePath: { eq: "owner-prop.png" }) {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
         reviews{
              reviewCount
                   }
        privateImg: file(relativePath: { eq: "private-prop.png" }) {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
        commercialImg: file(relativePath: { eq: "commercial-prop.png" }) {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
        site {
          siteMetadata {
            siteUrl
            company
          }
        }
      }
    `
  );
  const reviewCount = data.reviews.reviewCount; 
  const schemaImg = data.heroImg.publicURL;
  const logoImg = data.logoImg.publicURL;
  const heroImg = data.heroImg.childImageSharp.gatsbyImageData;
  const notepadImg = data.notepadImg.childImageSharp.gatsbyImageData;
  const ownerImg = data.ownerImg.childImageSharp.gatsbyImageData;
  const privateImg = data.privateImg.childImageSharp.gatsbyImageData;
  const commercialImg = data.commercialImg.childImageSharp.gatsbyImageData;
  const siteUrl = data.site.siteMetadata.siteUrl;
  const companyName = data.site.siteMetadata.company;
  const pageUrl = "/services/inspections";

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Prime Sparks",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
       {
        "@type": "ListItem",
        position: 2,
        name: " Services",
        item:{ 
          url:`${siteUrl}/services`,
          id:`${siteUrl}/services`,
      },
    },
      {
        "@type": "ListItem",
        position: 3,
        name: "Electrical Inspections and Reports",
        item:{ 
          url:`${siteUrl}/services/inspections`,
          id:`${siteUrl}/services/inspections`,
      },
    },
    ],
  };
  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": `${siteUrl}${pageUrl}#electrical-inspections-and-reports`,
    image: `${siteUrl}${schemaImg}`,
    mpn: `${siteUrl}`,
    sku: `${siteUrl}${pageUrl}`,
    description: "EICR's and PAT testing for your property.",
    logo: `${siteUrl}${logoImg}`,
    name: `${companyName}`,
    url: `${siteUrl}`,
    brand: {
      "@type": "Organization",
      logo: `${siteUrl}${logoImg}`,
      image: `${siteUrl}${schemaImg}`,
      name: `${companyName}`,
      url: `${siteUrl}`,
    },
    aggregateRating: {
      "@type": "AggregateRating",
     reviewCount: `${reviewCount}`,
      ratingValue: "5",
      bestRating: "5",
      worstRating: "1",
    },
    review: {
      "@type": "Review",
      url: `${siteUrl}/testimonials`,
      datePublished: "2021-05-08T17:58:29+00:00",
      reviewBody: "Friendly, professional service. Punctual and efficient. Would definitely recommend!",
      author: {
        "@type": "Person",
        name: "Susie",
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: `${companyName}`,
        sameAs: `${siteUrl}`,
      },
    },
  };
  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>
      <GatsbySeo
        title="Electrical Inspections and Reports in Eastbourne | Prime Sparks"
        description="EICR's and PAT testing for your property."
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: "Electrical Inspections and Reports | Prime Sparks",
          description: "EICR's and PAT testing for your property.",
          images: [
            {
              url: `${schemaImg}`,
              width: 1920,
              height: 1080,
              alt: "Electrical Inspections and Reports in Eastbourne",
            },
          ],
        }}
      />
      <ServiceHero
        heading="Electrical Inspections and Reports"
        text="EICR's and PAT testing for your property."
        isDarken={true}
        image={heroImg}
      />
      <DotsCta
        showCta="d-none"
        sHeading="EICRs in Eastbourne"
        heading={
          <span>
            Do you need an Electrical Installation Condition Report{" "}
            <span className="text-primary">(EICR)</span> for your <br />{" "}
            property?
          </span>
        }
        text={
          <span>
            <p>
              You may find yourself in a situation as a landlord or property
              owner where you need an EICR. So what is an EICR and when do you
              need one?
            </p>
            <p> An EICR is used to identify the following issues:</p>
            <ul className="dashed pl-0">
              <li>- Fixed wire circuit overloads </li>
              <li>- Potential electric shock risks</li>
              <li>- Potential fire risk</li>
              <li>- Insufficient earthing</li>
              <li>- Cable damage due to rodents, for example</li>
              <li>- Faulty electrical work</li>
            </ul>
          </span>
        }
      />

      <PhotoSide
        img={notepadImg}
        height="35rem"
        heading={
          <span>
            <span className="text-primary">When</span> do you need an EICR?
          </span>
        }
        cta="CONTACT US"
        ctaUrl="/contact-us"
        text={
          <span>
            <p>
              An EICR is the only way of confirming the safety of the fixed
              electrical installations in a property. These installations
              generally comprise: wiring, light fittings, sockets and consumer
              units (more commonly known as a fuse box).
            </p>
            <p>
              Certain appliances are not included in an EICR, such as fridges,
              but permanently connected items like extractor fans, for instance,
              are included.
            </p>
            <p>
              Portable appliance testing (PAT) is a service that Prime Sparks
              offers for non-permanent appliances. The recommended frequency for
              PAT testing is annually.
            </p>
          </span>
        }
      />
      <PhotoSide
        imgRight={true}
        showCta="d-none"
        img={ownerImg}
        height="25rem"
        heading="Owner-occupied properties"
        text="Although an EICR is not a legal requirement for homeowners, having one carried out at least every 10 years is recommended. An EICR also recommended for a property you plan to move in to, or in the case of a suspected electrical installation problem."
      />
      <PhotoSide
        imgRight={true}
        showCta="d-none"
        img={privateImg}
        height="25rem"
        heading="Private rented properties"
        text="There is a legal requirement for landlords of tenanted private rented properties to have an EICR and a new one should be issued at least every 5 years, or sooner if recommended by your electrician because of a suspected electrical installation problem."
      />
      <PhotoSide
        imgRight={true}
        showCta="d-none"
        img={commercialImg}
        height="25rem"
        heading="Commercial properties"
        text={
          <span>
            <p>
              Owner-occupiers of commercial properties are legally required to
              provide a safe working environment for their employees. The
              property owner could be liable in the event of an accident as a
              result of a faulty electrical installation and if no EICR has been
              issued.
            </p>
            <p>
              The frequency of an EICR for commercial properties depends on the
              type of commercial property and its current use.
            </p>
          </span>
        }
      />
      <Container>
        {" "}
        <Row>
          <Col className="text-center">
            <Link className="cta-link" to="/contact-us">BOOK YOUR EICR WITH US</Link>
          </Col>
        </Row>
      </Container>
      <Certifications />
      <Testimonials />
    </Layout>
  );
};
export default ElectricVehicle;
